:root {
    --primary-text-color: white;
    --primary-background-color: rgb(0, 0, 0);
    --menu-bar-height: 65px;
    --primary-color: rgb(40, 119, 255);
    --card-text-color: rgb(40, 119, 255);
    /* --primary-color: rgb(102, 155, 245);
    --card-text-color: rgb(102, 155, 245); */
    --icon-button-bg: rgb(67, 67, 67);
    --icon-button-hover-bg: rgb(94, 94, 94);

    --card-bg-color: rgb(23,23,23);
    --card-bg-hover: rgb(36, 36, 36);
}