.textImageContainer {
    display: flex;
}

.textContainer {
    flex: 1;
    padding-right: 50px;
}

.imageWrapper {
    flex: 1;
    text-align: center;
}

.imageContainer {
    margin-top: 30vh;
}

/* .imageRelative {
    width: 100%;
    height: 100%;
    position: relative;
} */

.profilePic {
    width: 120px;
    border-radius: 50%;
}

.aboutMeEmailIcon {
    margin-right: 10px;
    transform: translateY(2px);
}

.aboutMeEmail {
    color: var(--primary-text-color);
    text-decoration: none;
}