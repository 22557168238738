@import url("../../constants.css");

.aboutContent {
    width: 80%;
    max-width: 1200px; 
    padding: 20px;
    margin: auto;
    padding-top: calc(50vh - 325px);
    display: block;
}

.nameDescriptionContainer, .codeIconContainer {
    display: inline-block;
    width: 50%;
}

.codeIconContainer {
    max-width: 50%;
    text-align: center;
}

@keyFrames nameFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.name {
    opacity: 0;
    font-size: 3.5em;
    line-height: 1em;
    animation-name: nameFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.nameColor {
    color: var(--primary-color);
}

@keyFrames expandNameDescriptionDivider {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.nameDescriptionDivider {
    width: 0;
    height: 1px;
    background-color: var(--primary-text-color);
    margin: 15px 0;

    animation-name: expandNameDescriptionDivider;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

@keyFrames descriptionContainerFadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.descriptionContainer {
    width: 100%;
    overflow: auto;
    opacity: 0;
    padding: 20px 30px;
    background-color: rgb(17, 17, 17);
    border: 1px solid rgb(58, 58, 58);
    border-radius: 10px;
    animation-name: descriptionContainerFadein;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.description {
    font-family: monospace, 'Courier New', Courier;
}

.descriptionIndented {
    padding-left: 20px;
}

@media (max-width: 550px) {
    .aboutContent {
        width: 100%;
    }
}

.codeContainer {
    width: 100%;
    overflow: auto;
}


@media (max-width: 800px) {
    .aboutContent {    
        display: block;
        padding-top: 8vh;
    }
    .nameDescriptionContainer, .codeIconContainer {
        display: block;
        width: 100%;
    }
}

@keyFrames descriptionTyping {
    from {
        min-width: 0;
        max-width: 0;
    }
    to {
        min-width: fit-content;
        max-width: 1000px;
    }
}

.descriptionLine {
    overflow: hidden;
    min-width: 0;
    max-width: 0;
    white-space: nowrap;

    animation: descriptionTyping 1s steps(50, end);
    animation-fill-mode: forwards;
}

.descriptionContent {
    width: fit-content;
}

.descriptionLine1 {
    animation-delay: 0.5s;
}

.descriptionLine2 {
    animation-delay: 1.4s;
}

.descriptionLine3 {
    animation-delay: 2.4s;
}

.descriptionLine4 {
    animation-delay: 3.2s;
}

.descriptionLine5 {
    animation-delay: 4s;
}

.descriptionLine6 {
    animation-delay: 4.7s;
}

.descriptionPurple {
    color: rgb(196, 231, 255);
}

.descriptionBlue {
    color: var(--primary-color);
    color: rgb(194, 238, 255);
}

.descriptionRed {
    color: grey;
}

.descriptionGreen {
    color: rgb(255, 255, 255);
}

.descriptionYellow{
    color: rgb(194, 238, 255);
}

.descriptionGrey{
    color: grey;
}

.hovering>.descriptionPurple {
    color: rgb(240, 139, 218);
}

.hovering>.descriptionBlue {
    color: rgb(131, 154, 229);
}

.hovering>.descriptionRed {
    color: rgb(234, 132, 132);
}

.hovering>.descriptionGreen {
    color: rgb(144, 230, 144);
}

.hovering>.descriptionYellow{
    color: rgb(242, 242, 133);
}

.hovering>.descriptionGrey{
    color: grey;
}


.icons {
    position: fixed;
    bottom: 10px;
    right: 10px;
}



@media (max-width: 699px) {
    .icons {
        display: none;
    }
}


.languageButton {
    color: var(--primary-text-color);
    background-color: var(--icon-button-bg);
    padding: 5px 8px 3px 8px;
    text-align: center;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 3px;
}

.selectedButton {
    background-color: var(--primary-color);
}

.notSelectedButton:hover {
    cursor: pointer;
    background-color: var(--icon-button-hover-bg);
}

.languageSeparator {
    margin: 8px 0;
    width: 100%;
    height: 0.5px;
    background-color: var(--primary-text-color);
}