.imageWrapper {
    width: 50%;
    height: fit-content;
    margin: auto;
    margin-top: 5px;
}

.imageExampleContainer {
    width: 240px;
    width: 100%;
    aspect-ratio: 16/9;
    /* height: 135px; */
    padding-top: 56.25%;
    overflow: hidden;
    /* padding: none; */
    margin: auto;
    margin-top: 0;
    border-radius: 10px;
    border: 0.5px solid var(--primary-text-color);

    position: relative;
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.backgroundImage {
    min-height: 100%;
    width: 100%;

    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.foregroundImage {
    max-height: 100%;
    max-width: 100%;
}

@media (max-width: 699px) {
    .imageWrapper {
        width: 100%;
    }
}