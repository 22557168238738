@import url("./constants.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--primary-text-color);
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: black;
  border: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #414141;
  border-radius: 15px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(117, 117, 117);
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(113, 113, 113);
}

::-webkit-scrollbar-corner {
  background: none;
}

.notHighlightable {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fadeIn {
  animation: fadeIn 2s;
}