@import url("../../constants.css");

.chip {
    padding: 2px 15px;
    background-color: var(--card-text-color);
    color: var(--primary-text-color);
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    margin: 1px 2px;
    white-space: nowrap;
}