@import url("../../constants.css");

.headerBar {
    width: 100%;
    padding: 15px;
    position: fixed;
    background-color: var(--primary-background-color);
    z-index: 100;
}

.headerSpace {
    height: 65px;
}

.wideHeaderBar {
    text-align: center;
    height: var(--menu-bar-heigh);
}

.menuBar {
    display: inline-block;
}

.menuItem {
    margin: 0 20px;
    display: inline-block;
    color: var(--primary-text-color);
    text-decoration: none;
    position: relative;
    padding: 5px;
}

.menuItem:hover {
    cursor: pointer;
}

.menuItem::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-text-color);
    transform: scaleX(0);
    transition: transform 0.4s ease;
}

.menuItem:hover::after {
    transform: scaleX(1);
}

.menuItem.active::after {
    transform: scaleX(1);
}

@media (max-width: 699px) {
    .wideHeaderBar {
        display: none;
    }
}

.narrowMenuContainer {
    overflow: hidden;
    min-height: fit-content;
}

.narrowMenuScrollable {
    height: 100%;
    overflow: auto;
}

.narrowMenuList {
    min-height: 0;
    max-height: 0;
    transition-duration: 500ms;
}

.showNarrowMenuList {
    min-height: 100vh;
    max-height: 100vh;
}

.menuIcon {
    color: var(--primary-text-color);
    font-size: 1.8em;
}

.menuIcon:hover {
    cursor: pointer;
}

.menuListItem, .narrowMenuIconGroup {
    display: block;
    color: var(--primary-text-color);
    text-decoration: none;
    padding: 20px 15px;
}

.narrowMenuIconGroup {
    padding: 20px 10px 80px;
}

.menuListItem.active {
    text-decoration: underline;
}


@media (min-width: 700px) {
    .narrowHeaderBar {
        display: none;
    }
}

.narrowMenuScrollable::-webkit-scrollbar {
    display: none;
}