.listItemHeader {
    font-size: 1.3em;
}

.listItem {
    width: 100%;
    display: flex;
}

.bulletPoint {
    font-size: 0.8em;
    padding: 4px 16px 0 5px;
    display: inline;
    flex: 0;
}

.listItem1 {
    margin-top: 32px;
}

.lessSpaceListItem1 {
    margin-top: 12px;
}

.listItem2 {
    margin-top: 8px;
    padding-left: 32px;
}

.listItemContent {
    display: inline-block;
    flex: 1;
}


@media (max-width: 499px) {
    .listItem2 {
        padding-left: 16px;
    }
}