@import url("../../constants.css");

.button {
    padding: 12px 23px;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 2px;
}

.button:hover {
    cursor: pointer;
}

.primaryButton {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
}

.secondary {
    background-color: rgb(42, 42, 42);
}

.addPadding {
    margin-right: 8px;
}