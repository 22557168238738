@keyFrames fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.pageHeaderSection {
    padding-bottom: 5vh;
    margin: 10vh 0 5vh;
    opacity: 0;
    transition: 1s;

    display: flex;
}

.aboutMe {
    padding-bottom: 0;
    margin-top: 25vh;
    margin-bottom: 0;
}

.shortBottomPadding {
    padding-bottom: 1vh;
}

.pageHeaderTextContainer {
    flex: 1;
}

.pageHeaderTextContainer.hasImage {
    padding-left: 50px;
}

.pageHeaderImageWrapper {
    flex: 0;
}

.pageHeaderImageContainer {
    width: 150px;
    padding: 3px;
}

.pageHeaderImage {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.showHeader {
    opacity: 1;
}

.pageHeader {
    font-size: 2em;
}

@media (max-width: 699px) {
    .pageHeaderSection {
        display: block;
    }

    .pageHeaderTextContainer.hasImage {
        padding-left: 0;
    }

    .aboutMe {
        margin-top: 0vh;
        padding-top: 5vh;
    }
}