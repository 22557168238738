@import url("../../constants.css");

.fullPageContent {
    height: 100vh;
    /* background: rgb(0,0,0);
    background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 43%, rgba(25,25,25,1) 70%, rgba(40,40,40,1) 100%); */
    /* background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 48%, rgba(42,42,42,1) 77%, rgba(88,88,88,1) 100%); */
    
    /* background-color: rgb(0, 1, 16); */

    /* background: rgb(0,1,16);
    background: linear-gradient(0deg, rgba(0,1,16,1) 0%, rgba(0,1,16,1) 57%, rgba(10,12,38,1) 83%, rgba(19,20,46,1) 100%); */

    /* background-color: rgb(16, 0, 48); */
    background-color: rgb(0, 0, 0);
}

.fullPageBody {
    height: calc(100% - var(--menu-bar-height));
    overflow: auto;
}