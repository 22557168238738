.galleryContainer {
    max-width: 100%;
    max-height: fit-content;
    overflow-x: auto;
    overflow-y: hidden;

    opacity: 1;
    transition-duration: 500ms;

    scroll-snap-type: x mandatory;
}

.hideGalleryContainer {
    opacity: 0;
}

.gallery {
    white-space: nowrap;
    max-height: fit-content;
    max-width: 100%;
}

.galleryImage {
    display: inline-block;
    height: fit-content;
    max-width: 100%;
    padding: 0 20px;
    transition-duration: 1500ms;

    scroll-snap-align: center;
}

.galleryImage > img {
    max-height: 50vh;
    max-width: 100%;
}

.hideGalleryImage {
    opacity: 0;
}
