.responsivePage {
    margin: auto;
    padding-bottom: 100px;
    width: 70%;
    max-width: 1000px;
}



@media (max-width: 699px) {
    .responsivePage {
        width: 90%;
    }
}