@import url("../../constants.css");

/* @keyFrames fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */

.card {
    padding: 20px;
    max-width: 100%;
    border-radius: 10px;
    background: var(--card-bg-color);
    opacity: 1;
    transition: 1000ms;
    /* opacity: 0;
    animation: fadeIn 1s;
    animation-fill-mode: forwards; */
}

.hideCard {
    opacity: 0;
}

/* .workExperiencesCard:nth-of-type(2) {
    animation-delay: 1000ms;
} */

.cardHeader {
    font-size: 1.25em;
    color: var(--card-text-color);
}

.cardSubheader {
    font-weight: 500;
}

.expandable {
    cursor: pointer;
    transition: 500ms;
}

.expandable:hover {
    background-color: var(--card-bg-hover);
}

.expandableContent {
    overflow: hidden;
    max-height: 0;
    transition: 1s;
}

.showExpandableContent {
    max-height: 500px;
    /* overflow: auto; */
}

.scrollableExpandableContent {
    max-height: 300px;
    overflow: auto;
}

.workExperiencesCard {
    /* width: 400px; */
}

.workExperiencesContent {
    margin: 10px 25px 20px 25px;
}

.cardDivider {
    width: 100%;
    height: 3px;
    margin: 25px 0;
    background-color: var(--primary-text-color);
    background-color: var(--card-text-color);
}

.cardPrimaryText {
    color: var(--card-text-color);
    /* text-decoration: underline; */
    font-weight: 500;
    /* font-size: 1.1em; */
}

.baseContentContainer {
    width: 100%;
    display: flex;
}

.baseContent {
    flex: 1;
}

.expandableArrowContainer {
    flex: 0;
    position: relative;
}

.expandableArrow {
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.5em;
}

.expandableArrowButton {
    color: grey;
}



@media (max-width: 699px) {
    /* .card {
        padding-left: 10px;
        padding-right: 10px;
    } */

    .workExperiencesContent {
        margin-left: 15px;
        margin-right: 15px;
    }
}


@media (max-width: 399px) {
    .workExperiencesContent {
        margin-left: 5px;
        margin-right: 5px;
    }
}